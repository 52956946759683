<template>
	<v-container v-if="products.length == 0">{{ $t('product.loading') }}</v-container>
	<v-container fluid v-else>
		<v-container>
			<v-row dense>
				<v-col>
					<template v-for="(item, index) in parents">
						<router-link class="bread" :to="item.href" :key="item.href">{{ item.text}}</router-link>
						<span v-if="index < parents.length - 1" :key="item.href">&nbsp;&nbsp;>&nbsp;&nbsp;</span>
					</template>
<!--
					<v-breadcrumbs :items="parents">
						<template v-slot:divider>
							<v-icon>mdi-chevron-right</v-icon>
						</template>
					</v-breadcrumbs>
-->
				</v-col>
			</v-row>
			<v-row dense>
				<v-col class="d-flex flex-column" v-for="n in products" :key="n['id']" cols="12" sm="6" md="4" lg="3">
					<v-card class="flex d-flex flex-column" outlined tile elevation="24" route :to="create_route(n['id'])">
						<v-row dense class="incenter seda relatko">
							<v-col class="tileTagParent">
								<v-avatar class="tileTag" v-if="n.tileTag != 'none'"><v-img :src="tile_path(n.tileTag)"></v-img></v-avatar>
								<v-card-title class="headline">{{ n['code'] }}</v-card-title>
							</v-col>
							<v-btn v-if="show_cat" class="smaller btn_cat" right :title="$t('product.btn_title_add_to_catalogue')" @click.prevent="hello(n['id'])"><v-icon>mdi-folder-multiple-plus</v-icon></v-btn>
						</v-row>
						<v-row dense>
							<v-col class="incenter">
								<v-avatar class="profile" size="120" tile>
									<v-img contain :src="build_url(n['imgSrc'])"></v-img>
								</v-avatar>
							</v-col>
							
						</v-row>
						<v-row>
							<v-col>
								<v-card-subtitle class="incenter">{{ n['descriptionShort'] }}</v-card-subtitle>
							</v-col>
						</v-row>
						<!-- </div> -->
					</v-card>
				</v-col>
			</v-row>
			<v-dialog v-model="dialog" hide-overlay persistent>
				<v-card>
					<v-card-text>{{ $t('product.alert_added_to_catalogue') }}</v-card-text>
				</v-card>
			</v-dialog>		
		</v-container>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service"

export default {
	data: () => ({
		products: [],
		parents: [],
		show_cat: false,
		dialog: false
	}),
	methods: {
		create_route(id) {
			return '/product/' + id
		},
		bread(parents) {
                        let breadc = "";
                        for(let i = 0; i < parents.length; i++) {
                                if(i < parents.length - 2) breadc = breadc + '<router-link :to="' + parents[i].href + '">' + parents[i].text + '</router-link>&nbsp;>&nbsp;'
                                else breadc = breadc + '<router-link :to="' + parents[i].href + '">' + parents[i].text + '</router-link>'
                        }
                        return breadc;
                },
		tile_path(img) {
			var kk = new Date().getTime()
			return '/pictures/picture/plLabel/' + img + '?' + kk
		},
		build_url(img) {
			return '/pictures/picture/pl/' + img
		},
		hello(ID_pl) {
			this.dialog = true
			this.$store.commit('add_to_catalogue', ID_pl)
		}
	},
	watch: {
		dialog(val) {
			if (!val) return
			setTimeout(() => (this.dialog = false), 1000)
		},
	},
	mounted() {
		this.show_cat = this.$store.getters.is_authenticated
		ApiService.get('/cat/' + this.$route.params.id, false).then(response => {
			this.products = response.data.products
			this.parents = response.data.parents
		}).catch(() => {})
	}
};
</script>

<style scoped>
.incenter {
	text-align: center;
}
.relatko {
	position: relative;
}
.btn_cat {
	position: absolute;
	top: 0;
	right:0;
}
.lolo {
	display : flex;
	align-items : center;
	justify-content: center;
}
.smaller {
	font-size: 0.65em;
}
.v-card__title {
	padding-top: 0px;
}
.headline {
	font-size: 1.2rem !important;
	display: block;
	padding: 0;
}
.seda {
	background-color: rgb(245, 245, 245);
	margin: 0;
}
.tileTagParent {
	position: relative;
}
.tileTag {
	position: absolute;
	top: -25px;
	left: -15px;
}
.bread {
	color: #1976d2;
	text-decoration: none;
	cursor: pointer;
	font-size: 14px;
}
</style>
